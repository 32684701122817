<template>
  <v-list-item two-line dense>
    <v-list-item-content>
      <v-list-item-title>
        <a
          @click="download"
          class="file-uploader-download-button blue--text text-decoration-underline"
        >
          <v-icon size="14" left>fa fa-download</v-icon>
          <span>{{ filename }}</span>
        </a>
      </v-list-item-title>

      <v-list-item-subtitle v-if="getPrettyBytes(file.size)">
        {{ 'file.size'|t }}: {{getPrettyBytes(file.size)}}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon v-if="!disabled">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small @click="copyFileUrl">
            <v-icon>
              fa fa-copy
            </v-icon>
          </v-btn>
        </template>

        <span>{{'action.copy_link'| t}}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="error" icon small @click="removeConfirm">
            <v-icon>
              fa fa-trash
            </v-icon>
          </v-btn>
        </template>

        <span>{{'action.remove'| t}}</span>
      </v-tooltip>
    </v-list-item-icon>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
import downloadjs from 'downloadjs'
import prettyBytes from 'pretty-bytes'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    index: Number,
    file: Object,
  },
  data: () => ({
    downloadLoading: false,
  }),
  created() {},
  methods: {
    getPrettyBytes(size) {
      if(isNaN(size)) return null
      return prettyBytes(size)
    },
    async copyFileUrl() {
      try {
        await this.$copyText(window.eagleLodash.cloneDeep(this.fileUrl))
        this.$snotify.success(
          this.fileUrl,
          this.$t('copy.successfully')
        )
      } catch (error) {
        console.warn(error)
      }
    },
    removeConfirm() {
      this.$apopup.base({
        title: this.$t('file.remove.confirm'),
        content: `${this.filename}`,
        applyCallback: () => {
          this.$emit('removeFile', this.index)
        },
      })
    },
    async download() {
      // 可預覽類型
      if(this.isPreviewType) {
        window.open(this.fileUrl)
        return
      }

      // 不可預覽, 直接跳下載確認popup
      this.$apopup.base({
        title: this.$t('download.confirm'),
        content: `${this.filename}`,
        applyCallback: () => {
          this.startDownloadFileRequest()
        },
      })
    },
    async startDownloadFileRequest() {
      if(this.downloadLoading == true) return
      this.downloadLoading = true
      try {
        const downloadResult = await this.downloadFileRequest({
          path: this.file.path,
        })

        const data = downloadResult.data
        downloadjs(new Blob([data]), this.filename, data.type)
      } catch (error) {
        console.error(error)
      } finally {
        this.downloadLoading = false
      }
    },
  },
  computed: {
    isPreviewType() {
      if(this.file.file_type === 'text/plain') return true
      if(this.file.file_type === 'application/pdf') return true
      if(this.file.file_type === 'application/json') return true
      if(this.file.file_type === 'text/html') return true
      if(new RegExp('image/').test(this.file.file_type)) return true
      return false
    },
    fileBaseUrl() {
      return this.$store.getters['base/application'].fileBaseUrl
    },
    downloadFileRequest() {
      return this.$api.collection.fileApi.download
    },
    filename() {
      if(!this.file) return null
      return this.file.filename
    },
    fileUrl() {
      if(!this.file) return null
      const path = window.eagleLodash.cloneDeep(this.file.path).substring(1)
      return `${this.fileBaseUrl}${path}`
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.file-uploader-download-button
  cursor: pointer
</style>
